import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 2000,
    showConfirmButton: true
  })
}

export function actionError(msg,action){
  PopSwal.fire({
    icon: 'danger',
    title: msg,
    showCancelButton: false,
    confirmButtonColor: "#e72520",
    confirmButtonText: "Ok",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      action()
    }
  });  
}

export function updateloginStatus(status = false,expires=180){
  Cookies.set("islogged", status, {path: "/", expires : expires});
}

export function setToken(token,expires=180){
  Cookies.set("obulus", token, {path: "/", expires : expires});
}

export function setCandy(candy,expires=180){
  Cookies.set("candy", candy, {path: "/", expires : expires});
}


export function getToken(){
  return Cookies.get("obulus");
}


export function getCandy(){
  return Cookies.get("candy");
}

export function getIntro(){
  return Cookies.get("_i");
}

export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' });
  Cookies.remove("candy",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
}

export function isFirstTime(){
  return getIntro() == null ? true : false;
}

let HOST = null;
let url = window.location.hostname;

if(url.includes("catchnshop.com")){
  HOST = "https://api.catchnshop.com";
}else{
  HOST = "http://127.0.0.1:8000";
  //HOST = "https://llapi.apacconnects.com";
}

export const APIHOST = `${HOST}/api/`
